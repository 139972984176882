import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1568.000000 1515.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1515.000000) scale(0.100000,-0.100000)">


<path d="M0 7575 l0 -7575 7840 0 7840 0 0 7575 0 7575 -7840 0 -7840 0 0
-7575z m8025 5180 c291 -24 521 -58 780 -116 1792 -404 3256 -1715 3838 -3439
374 -1106 348 -2339 -70 -3430 -508 -1324 -1547 -2396 -2863 -2953 -769 -325
-1576 -461 -2445 -411 -158 9 -502 46 -546 60 -9 2 -54 11 -100 19 -71 12
-156 30 -244 51 -11 3 -31 7 -44 10 -165 34 -703 213 -811 269 -14 7 -54 25
-90 40 -107 45 -407 200 -535 276 -655 390 -1219 912 -1645 1523 -308 441
-581 1005 -718 1481 -367 1273 -248 2584 339 3740 114 226 136 265 256 453 86
136 263 384 318 446 6 6 50 61 99 121 93 114 491 519 561 570 22 16 45 34 50
40 6 5 39 34 75 63 36 29 74 60 84 69 58 47 215 163 221 163 4 0 32 19 63 43
48 35 146 99 220 142 10 6 31 19 47 31 28 20 43 28 140 77 22 12 42 23 45 27
6 6 330 167 410 203 30 14 87 37 125 51 39 15 86 34 105 43 19 8 44 17 54 19
11 2 24 8 30 14 5 5 20 10 31 10 12 0 25 3 29 8 4 4 11 7 14 7 4 1 32 9 62 20
30 10 55 17 55 16 0 -1 32 9 70 23 184 66 665 166 965 201 329 38 716 45 1025
20z"/>
<path d="M7330 12339 c-952 -54 -1895 -398 -2657 -971 -357 -268 -705 -616
-965 -963 -492 -656 -799 -1406 -909 -2215 -49 -366 -49 -859 1 -1226 121
-898 483 -1715 1071 -2419 46 -55 154 -171 239 -259 560 -573 1222 -983 1995
-1236 88 -29 167 -53 175 -55 8 -2 49 -12 90 -23 183 -49 406 -93 585 -116 22
-3 58 -8 80 -11 572 -76 1265 -27 1855 131 996 267 1892 849 2537 1649 599
742 952 1626 1033 2580 13 156 13 579 0 742 -70 862 -365 1667 -869 2373 -206
288 -502 613 -752 826 -163 138 -189 159 -358 281 -47 34 -97 70 -111 80 -129
97 -469 288 -695 391 -740 339 -1530 487 -2345 441z m-2150 -1703 c127 -26
181 -49 285 -118 143 -96 247 -229 329 -421 20 -47 36 -183 36 -310 0 -92 1
-98 23 -106 12 -5 294 -14 627 -20 651 -13 866 -23 910 -46 15 -8 58 -17 95
-21 50 -5 74 -13 93 -30 20 -19 36 -24 79 -24 38 0 55 -4 59 -15 3 -8 22 -17
42 -21 61 -10 71 -14 66 -28 -4 -10 8 -16 40 -20 54 -8 58 -18 14 -31 -28 -9
-36 -7 -56 13 -13 12 -34 22 -48 22 -13 0 -34 6 -45 13 -18 10 -23 9 -33 -7
-6 -10 -21 -28 -33 -39 -13 -11 -23 -26 -23 -33 0 -7 -10 -20 -22 -28 -19 -13
-19 -17 -7 -32 11 -13 11 -18 2 -21 -7 -3 -13 -15 -13 -27 0 -12 -13 -36 -30
-54 -21 -23 -30 -28 -30 -17 0 9 -4 13 -10 10 -5 -3 -10 -32 -10 -63 0 -65
-16 -90 -72 -115 -41 -19 -68 -22 -68 -7 0 6 5 10 10 10 6 0 10 5 10 10 0 6
-9 10 -20 10 -13 0 -20 -7 -20 -18 0 -10 -4 -22 -9 -27 -5 -6 -11 -26 -13 -45
-2 -19 -7 -34 -13 -32 -5 2 -12 -7 -15 -18 l-5 -22 -700 -4 c-385 -2 -714 -6
-731 -9 -23 -4 -35 -13 -42 -33 -7 -17 -13 -273 -16 -687 -6 -610 -12 -731
-32 -651 -3 14 -10 26 -15 26 -6 0 -7 43 -3 105 5 75 4 105 -4 105 -8 0 -11
-21 -8 -63 3 -61 2 -64 -26 -80 -38 -22 -44 -43 -25 -81 47 -90 33 -116 -64
-116 -80 0 -104 -16 -115 -74 -4 -26 -11 -36 -25 -36 -13 0 -20 -10 -25 -34
-3 -19 -3 -39 0 -45 4 -5 -3 -15 -14 -21 -16 -8 -20 -8 -20 4 0 13 -23 28 -37
23 -4 -1 -8 -10 -8 -19 -1 -10 -10 -27 -21 -39 -14 -16 -15 -23 -7 -26 10 -4
9 -10 -2 -26 -8 -12 -15 -29 -15 -39 0 -10 -4 -18 -8 -18 -4 0 -16 -15 -27
-32 -10 -18 -27 -42 -37 -52 -10 -11 -18 -26 -18 -33 0 -8 -4 -11 -10 -8 -5 3
-10 1 -10 -6 0 -8 -6 -6 -18 4 -10 10 -23 17 -29 17 -5 0 -15 24 -22 54 -7 30
-17 56 -23 58 -7 3 -15 35 -19 72 -4 43 -12 71 -22 79 -11 8 -18 37 -23 92 -3
44 -13 91 -22 105 -13 21 -17 83 -23 370 -12 554 -19 2819 -9 2846 12 29 9 29
150 0z m5801 -977 c15 -29 2 -140 -26 -224 -58 -170 -158 -303 -305 -403 -164
-111 -282 -141 -559 -142 -176 0 -202 -2 -290 -26 -311 -84 -551 -311 -659
-624 l-26 -75 -5 -520 c-4 -286 -9 -525 -13 -531 -4 -6 -12 -50 -18 -99 -43
-348 -249 -699 -552 -941 -164 -131 -278 -198 -438 -256 -236 -86 -353 -97
-1077 -97 -674 -1 -620 -10 -609 98 20 199 136 397 308 528 73 55 130 86 222
120 65 23 90 26 296 33 131 5 262 15 313 24 181 33 325 112 462 251 71 72 93
99 154 195 25 39 68 138 92 215 21 67 22 92 29 590 7 463 10 533 28 638 12 65
24 122 27 127 4 6 15 38 25 73 31 105 80 207 172 357 53 85 214 262 312 342
15 13 36 30 45 38 20 18 155 109 196 132 189 106 389 165 632 187 54 5 358 9
675 9 569 1 578 1 589 -19z m-5728 -2751 c-6 -7 -13 -20 -16 -28 -3 -9 -6 -3
-6 13 -1 19 4 27 15 27 12 0 14 -4 7 -12z m-243 -1630 c0 -12 -7 -59 -16 -106
-8 -46 -13 -86 -11 -89 3 -2 19 3 36 11 36 19 136 21 171 3 98 -49 91 -233
-12 -323 -57 -50 -113 -67 -199 -60 -41 3 -94 5 -118 5 -41 0 -43 1 -37 24 10
42 76 450 76 473 0 16 -9 25 -30 33 -16 5 -30 14 -30 20 0 12 70 28 128 30 37
1 42 -2 42 -21z m1060 -5 c0 -16 -15 -120 -34 -233 -52 -313 -50 -278 -10
-286 19 -3 33 -11 31 -18 -2 -7 -45 -12 -121 -14 -92 -2 -117 0 -114 10 3 7
19 17 36 22 36 10 35 7 77 276 14 91 27 173 30 183 2 14 -6 23 -32 33 -25 11
-32 19 -25 26 14 14 67 26 120 27 39 1 42 -1 42 -26z m300 18 c0 -5 -18 -119
-40 -253 -22 -134 -40 -251 -40 -261 0 -11 10 -20 30 -23 17 -4 30 -13 30 -20
0 -11 -25 -14 -120 -14 -100 0 -118 2 -114 14 3 8 19 18 35 21 16 4 31 10 34
15 5 8 75 434 75 457 0 6 -13 16 -30 22 -37 13 -39 29 -2 37 15 3 32 7 37 9
23 8 105 4 105 -4z m1995 -18 c-3 -16 -11 -64 -19 -108 -8 -44 -13 -81 -11
-83 2 -2 18 4 35 13 40 20 125 23 168 6 100 -42 101 -220 3 -319 -65 -65 -105
-73 -301 -63 -14 1 -33 1 -44 1 -10 -1 -19 0 -21 2 -1 2 15 104 36 228 48 281
48 285 9 299 -36 12 -39 29 -7 35 12 3 31 7 42 9 11 3 41 5 67 6 46 1 47 0 43
-26z m-1269 -113 l-7 -50 59 0 c66 0 72 -3 65 -31 -5 -16 -14 -19 -69 -19 -35
0 -64 -3 -64 -6 0 -4 -9 -60 -20 -126 -25 -146 -21 -158 50 -158 42 0 50 -3
50 -19 0 -35 -112 -55 -175 -31 -45 17 -49 50 -26 195 12 72 21 133 21 138 0
4 -16 7 -36 7 -52 0 -43 32 12 42 36 8 42 13 61 58 19 45 24 50 53 50 l33 0
-7 -50z m-304 -67 c41 -20 59 -64 56 -131 l-3 -57 -149 -3 -149 -3 6 -37 c4
-24 17 -47 37 -64 26 -24 39 -28 93 -28 34 0 77 4 95 9 35 10 43 -2 20 -31
-35 -43 -201 -53 -276 -17 -87 43 -105 156 -42 266 59 103 209 149 312 96z
m2389 17 c71 -14 83 -27 74 -79 -6 -35 -10 -41 -31 -41 -19 0 -29 8 -39 34
-30 71 -130 51 -172 -34 -33 -63 -32 -149 1 -187 24 -27 27 -28 119 -27 92 0
95 0 87 -20 -10 -29 -62 -45 -150 -45 -105 -1 -154 22 -181 85 -16 36 -19 57
-14 93 18 117 85 195 192 220 52 12 53 12 114 1z m479 -18 c54 -26 80 -73 80
-146 -1 -100 -55 -184 -142 -220 -54 -23 -174 -21 -218 3 -73 38 -99 122 -68
222 13 47 68 113 110 135 69 36 170 38 238 6z m572 -3 c26 -18 27 -18 74 3 56
24 141 29 178 9 43 -24 48 -59 26 -197 -11 -68 -20 -128 -20 -133 0 -5 14 -12
30 -16 32 -7 35 -12 22 -33 -13 -20 -207 -12 -207 8 0 8 12 20 26 27 23 11 29
23 38 76 21 121 25 186 11 202 -20 24 -111 18 -147 -9 -1 -1 -9 -47 -18 -101
-8 -55 -17 -114 -20 -131 -5 -28 -2 -33 20 -38 18 -5 25 -12 23 -24 -3 -14
-18 -17 -97 -20 -52 -1 -98 1 -103 6 -11 11 17 42 38 42 8 0 14 6 14 13 0 6 9
62 20 122 17 97 17 113 4 133 -11 17 -24 22 -57 22 -23 0 -53 -6 -67 -13 -26
-13 -36 -50 -58 -211 l-8 -65 28 -6 c31 -7 31 -7 22 -29 -5 -13 -25 -16 -110
-16 -98 0 -104 1 -104 20 0 15 9 22 30 27 17 3 30 6 30 7 0 0 9 53 20 116 26
153 26 156 -10 168 -42 15 -29 36 31 50 72 17 89 15 89 -8 0 -24 3 -24 40 -5
73 38 163 39 212 4z m-4742 19 c0 -2 -11 -68 -25 -148 -21 -129 -22 -148 -9
-167 11 -18 24 -23 59 -23 63 0 94 17 100 54 2 17 9 60 15 96 6 36 13 79 15
96 5 29 2 33 -27 43 -29 10 -30 13 -17 27 10 9 43 17 87 21 l72 6 -5 -29 c-23
-134 -45 -276 -45 -297 0 -18 7 -26 26 -31 40 -10 23 -30 -34 -40 -60 -10 -82
-4 -82 23 0 19 -2 19 -52 -4 -63 -30 -151 -34 -186 -9 -37 26 -42 55 -28 151
29 183 29 169 0 182 -52 23 -10 47 89 50 26 0 47 0 47 -1z m2054 -8 c20 -7 26
-17 26 -39 0 -16 -3 -36 -6 -45 -9 -23 -51 -20 -59 4 -10 32 -34 50 -68 50
-43 0 -77 -25 -77 -55 0 -29 24 -46 99 -70 69 -22 101 -53 101 -97 0 -48 -32
-92 -84 -118 -39 -19 -59 -21 -131 -19 -69 3 -88 8 -105 25 -17 17 -19 26 -12
60 6 29 13 40 30 42 17 3 25 -5 38 -37 13 -34 21 -40 54 -46 24 -4 50 -1 74 9
30 13 36 20 36 46 0 33 -9 39 -111 74 -79 28 -105 81 -72 146 28 53 83 80 170
80 39 0 83 -4 97 -10z m262 -12 c-3 -13 -15 -80 -27 -149 -27 -163 -23 -173
62 -167 34 2 66 11 74 19 19 19 54 251 39 256 -6 2 -20 10 -30 18 -30 22 -7
34 78 41 l76 7 -15 -89 c-7 -49 -20 -127 -27 -172 l-14 -84 30 -10 c55 -19 15
-44 -74 -47 -32 -1 -38 2 -38 19 0 25 -7 25 -61 0 -28 -12 -66 -20 -104 -20
-108 0 -127 35 -101 185 22 124 21 152 -5 160 -27 9 -20 37 11 46 14 4 49 7
78 8 49 1 52 0 48 -21z m968 -284 c39 -38 1 -94 -64 -94 -29 0 -42 5 -50 19
-14 27 -13 35 11 65 24 30 78 36 103 10z m1772 -210 c-3 -9 -6 -24 -6 -35 0
-19 -48 -19 -2911 -19 l-2911 0 6 23 c3 12 6 28 6 35 0 9 589 12 2911 12 2650
0 2911 -1 2905 -16z"/>
<path d="M7133 8993 c-18 -7 -16 -40 2 -46 8 -4 37 -3 65 0 41 5 48 8 39 19
-6 8 -18 14 -25 14 -8 0 -14 5 -14 10 0 10 -45 12 -67 3z"/>
<path d="M5214 7074 c3 -9 6 -35 6 -58 0 -31 4 -43 15 -43 8 0 12 4 9 9 -3 4
0 8 6 8 8 0 10 9 5 28 -4 15 -7 36 -7 47 1 14 -5 21 -19 22 -15 1 -19 -3 -15
-13z"/>
<path d="M5022 5049 c-19 -4 -38 -11 -42 -15 -10 -11 -53 -254 -46 -261 11
-12 73 -19 104 -12 68 15 111 86 112 180 0 90 -42 126 -128 108z"/>
<path d="M8338 5038 c-21 -17 -59 -253 -44 -268 5 -5 34 -10 64 -10 49 0 58 3
92 38 29 28 40 51 49 93 22 105 -10 159 -94 159 -28 0 -58 -6 -67 -12z"/>
<path d="M6630 5057 c-29 -15 -70 -72 -70 -99 0 -17 7 -19 93 -16 l92 3 -2 45
c-3 69 -51 98 -113 67z"/>
<path d="M9504 5059 c-17 -5 -39 -17 -48 -28 -60 -66 -60 -242 -1 -265 31 -11
90 -6 114 10 37 26 61 90 61 166 0 56 -4 71 -22 91 -27 30 -61 38 -104 26z"/>
</g>
</svg>



    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
